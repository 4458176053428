/* service.css */

.service-page {
    width: 100%;
    height: 100%;
    background: url('../assets/background2.jpg') no-repeat center center fixed;
    background-size: cover;
    color: white;
}

.service-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    border-radius: 8px;
    margin: 2rem auto;
    width: 100%;
}

.service-description {
    width: 60%;
    padding-right: 1rem;
}

.service-images {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
}

.service-images img {
    width: calc(33.33% - 10px);
    margin: 5px;
    border-radius: 8px;
}

.get-info {
    margin-top: 1rem;
}

.get-info input {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 4px;
    border: none;
}

.get-info button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    background-color: #1E90FF;
    color: white;
    cursor: pointer;
}

.service-facts {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    border-radius: 8px;
}

.fact {
    width: 30%;
    text-align: center;
    padding: 1rem;
    transition: transform 0.3s, background-color 0.3s;
}

.fact:hover {
    transform: scale(1.05);
    background-color: rgba(30, 144, 255, 0.2); /* Light blue background on hover */
}

.fact h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.fact .medium {
    font-size: 1.5rem;
}

.fact .small {
    font-size: 1rem;
}
