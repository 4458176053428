/*!* slider.css *!*/

/*body, html {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    width: 100%;*/
/*    overflow-x: hidden;*/
/*}*/

/*.slider {*/
/*    position: relative;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    overflow: hidden;*/
/*}*/

/*.slide {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    opacity: 0;*/
/*    transition: opacity 1s ease-in-out;*/
/*}*/

/*.slide.active {*/
/*    opacity: 1;*/
/*}*/

/*.slide-image {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    object-fit: cover;*/
/*    filter: brightness(50%); !* Makes the image dim *!*/
/*}*/

/*!* Add this for a translucent overlay *!*/
/*.slide::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: rgba(0, 0, 0, 0.4); !* Adjust opacity for transparency *!*/
/*    z-index: 1;*/
/*}*/

/*.slider-text {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    color: white;*/
/*    text-align: center;*/
/*    z-index: 2; !* Ensure text is above the overlay *!*/
/*}*/

/*.slider-text h1 {*/
/*    font-size: 2.5rem;*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.slider-text p {*/
/*    font-size: 1.5rem;*/
/*}*/

/* slider.css */

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
}

.slider {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    max-height: 600px;
    margin-top: 85px;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    align-items: center;
}

.slide.active {
    opacity: 1;
}

.slide-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: brightness(50%); /* Makes the image dim */
}

/* Add this for a translucent overlay */
.slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust opacity for transparency */
    z-index: 1;
}

.slider-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2; /* Ensure text is above the overlay */
}

.slider-text h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.slider-text p {
    font-size: 1.5rem;
}

/* New styles for the Request Demo button */
.request-demo-btn {
    display: inline-block;
    margin-top: 1.5rem;
    padding: 0.8rem 2rem;
    background-color: #C47B44; /* Brownish-orange color */
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.request-demo-btn:hover {
    background-color: #A65A2D; /* Darker shade for hover */
}

@media (max-width: 768px) {
    .slider {height: 65vh;}
    .slider-text {
        left: 0;
        right: 0;
        transform: none;
        width: 80%;
        top: 30%;
        margin: 0 auto;
    }
    .slider-text h1 {
        font-size: 1.75em;
    }
    .slider-text p {
        font-size: 1.25em;
    }
}