/* Improved Navbar Styles */

.navbar {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: space-around;
    gap: 1em;
    padding: 1rem 2rem;
    background-color: rgba(34, 51, 84, 0.9); /* Dim blue with slight transparency */
    border-bottom: 2px solid #ccc;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: fixed; /* Keeps navbar fixed at the top */
    width: 100%;
    top: 0;
    z-index: 100;
}

.navbar-logo img {
    height: 50px;
}

.navbar-links {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 0;
    padding: 0;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #f39c12; /* Highlight color on hover */
}

.navbar-links .dropdown {
    position: relative;
    display: inline-block;
}

.navbar-links .dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(60, 60, 60, 0.95); /* Darker dropdown background */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
}

.navbar-links .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.navbar-links .dropdown-content a:hover {
    background-color: #555; /* Softer hover effect */
}

.navbar-links .dropdown:hover .dropdown-content {
    display: block;
}

.search-bar {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.8); /* Softer input background */
}

.contact-consultant {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: white;
}

.contact-consultant .phone-icon {
    margin-right: 0.5rem;
}

.navbar-item {
    padding: 1rem;
    transition: background-color 0.3s ease;
}

.navbar-item:hover {
    background-color: rgba(51, 51, 51, 0.8); /* Dimmer hover effect */
}

.contact-link {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}