/* Global CSS reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* homepage.css */

html, body {
    width: 100%;
    height: 100%;
}

body {
    font-family: Arial, sans-serif;
    color: white;
    overflow-x: hidden;
    /*background: url('./media/next-background.jpg') no-repeat center center fixed;*/
    /*background: url('../assets/background2.jpg') no-repeat center center fixed;*/

    /*background-size: cover;*/
    background:
            linear-gradient(
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
            ),
            url('../assets/background2.jpg') no-repeat center center fixed;
    background-size: cover;

}

.homepage-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.home {
    padding: 2rem;
    background-color: rgba(0, 31, 63, 0.8); /* Semi-transparent Navy Blue */
    color: white;
}

/* Grow business section */
.grow-business {
    padding: 2em;
    /* text-align: center; */
    max-width: 800px;
    margin: 0 auto;
}
.grow-business h2 {
    font-size: 2em;
    margin-bottom: 1em;
}
.grow-business p {
    font-size: 1.2em;
    margin-bottom: 1em;
}
.what-we-offer {
    padding: 2rem;
    background-color: rgba(75, 75, 120, 0.5); /* Semi-transparent grey */
    margin-top: 2rem;
    border-radius: 8px;
    text-align: center;
}
.what-we-offer h2 {
    font-size: 2em;
}
.services-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.services-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-row h3 {
    margin-bottom: 2rem;

}

.services-column {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: black;
    padding: 1rem;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
    height: 300px;
    text-decoration: none;
}
@media (max-width: 768px) {
    .home {padding: 2em 0;}
    .what-we-offer {
        text-align: center;
    }
    .services-column {
        display: inline-block;
        text-align: center;
    }
    .service {
        display: inline-block !important;
        width: 40%;
        vertical-align: top;
        margin: 1em;
    }
}
@media (max-width: 600px) {
    .service {
        width: 100%;
        margin: .5em 0;
    }
}
/* .service:hover {
    transform: scale(1.05);
} */

.service-icon {
    width: 100%;
    height: 50%; /* Takes up the top half */
    object-fit: cover; /* Ensures the image covers the entire area */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.service h4, .service p {
    margin: 0.5rem 0;
    text-align: center;
}

.service p {
    font-size: 0.9rem;
}

.service a {
    text-decoration: none;
    color: black;
}

.service:hover a {
    color: #001f3f; /*Change link color on hover */
    text-decoration: none; /* Ensure no underline on hover */
}

/* Ensure no underline for all links within .service */
.service h4, .service p {
    text-decoration: none;
}
