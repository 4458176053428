/* contact.css */

.contact-page {
    background-color: rgba(34, 51, 84, 0.9);
}

/* Add padding to the top of the container to avoid content being hidden under the navbar */
.contact-container {
    padding: 8rem 2rem 2rem; /* Increased top padding to push content below the navbar */
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.contact-container h3 {
    margin-bottom: 1rem;
    font-size: 2em;
}

.contact-container p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

.contact-container a {
    color: #C47B44;
    text-decoration: none;
}

.contact-container a:hover {
    text-decoration: underline;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;

    height: 1px;
    margin: -1px;
    overflow: hidden;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    /* border: 1px solid #ccc; */

    color: #FFFFFF;
    /* background: none; */
    background: rgba(34, 51, 84, 0.9);
    outline: none;
    font-size: 1em;

    border: none;
    border-bottom: 1px solid #FFFFFF;
}

.form-group textarea {
    font-size: 16px;
}

/* .form-group input::placeholder,
.form-group select::placeholder,
.form-group textarea::placeholder {
    font-size: 1.25em;
} */

/* .form-group textarea {
    height: 100px;
} */

.submit-button {
    padding: 0.75rem;
    background-color: #C47B44;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1em;
    text-transform: uppercase;
}

.submit-button:hover {
    background-color: #A65A2D;
}
.success-message,
.error-message {
    font-size: 1.25em;
    margin-bottom: 2em;
}
.success-message {
    color: #2ecc71;
}
.error-message {
    color: #e74c3c;
}