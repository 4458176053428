
.footer {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-slogan h2 {
    margin-bottom: 2rem;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    margin-bottom: 2rem;
}
.footer-content .right {
    text-align: right;
}

@media (max-width: 768px) {
    .footer-content {
        display: block;
    }
    .footer-content .right {
        margin-top: 2em !important;
        text-align: left;
    }
}

.footer-content .services, .footer-content .company {
    flex: 1;
    margin: 0 1rem;
}
.footer h2 {
    font-size: 2em;
}
.footer-content h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.footer-content ul {
    list-style: none;
    padding: 0;
}

.footer-content ul li {
    margin-bottom: 0.5rem;
}

.footer-logo img {
    width: 150px;
    cursor: pointer;
}
