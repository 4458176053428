/* ses4.css */

.ses4-page {
    width: 100%;
    height: 100%;
    background: url('../assets/background5.jpg') no-repeat center center fixed;
    background-size: cover;
    color: white;
}

.ses4-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    border-radius: 8px;
    margin: 2rem auto;
    width: 100%;
}

.ses4-description {
    width: 60%;
    padding-right: 1rem;
}

.ses4-images {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
}

.ses4-images img {
    width: calc(33.33% - 10px);
    margin: 5px;
    border-radius: 8px;
}

.ses4-get-info {
    margin-top: 1rem;
}

.ses4-get-info input {
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 4px;
    border: none;
}

.ses4-get-info button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    background-color: #1E90FF;
    color: white;
    cursor: pointer;
}

.ses4-facts {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    border-radius: 8px;
}

.ses4-fact {
    width: 30%;
    text-align: center;
    padding: 1rem;
    transition: transform 0.3s, background-color 0.3s;
}

.ses4-fact:hover {
    transform: scale(1.05);
    background-color: rgba(30, 144, 255, 0.2); /* Light blue background on hover */
}

.ses4-fact h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.ses4-medium {
    font-size: 1.5rem;
}

.ses4-small {
    font-size: 1rem;
}

.ses4-details {
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    border-radius: 8px;
    margin: 2rem auto;
    width: 90%;
    max-width: 1200px;
    color: white;
}

.ses4-details h2 {
    margin-bottom: 1rem;
}

.ses4-details p, .ses4-details ul {
    margin-bottom: 1rem;
    line-height: 1.6;
}

.ses4-details ul {
    list-style: none;
    padding: 0;
}

.ses4-details ul li {
    margin-bottom: 0.5rem;
}

.ses4-details ul li strong {
    color: #1E90FF; /* Light blue color for emphasis */
}

.ses4-request-demo-button {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #1E90FF;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.ses4-request-demo-button:hover {
    background-color: #0D6EFD; /* Darker blue on hover */
}
